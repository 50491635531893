html,
body {
  margin: 0;
  padding: 0;
  height: 100% !important;
}

#root{
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 5px;
  margin-right: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(224, 224, 224, 0.046);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 90px;
  background: #bfbfbf83;
  mix-blend-mode: soft-light;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d3d2d263;
}
